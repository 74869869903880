import * as PIXI from "pixi.js";
import Vector from "../../util/vector";

export default class Feather {
    constructor(x, y, type, resource) {
        //velocityは単位ベクトルにする
        this.resource = resource;
        this.rad = Math.PI * Math.random(); //初期ラジアン
        this.mass = 1; //質量
        this.type = type; //羽の種類（ぼかしあり・なし）
        this.scale = 1;
        this.position = new Vector(x, y);
        this.velocity = this.setVelocity();
        this.angle = 0;
        this.angleVariable = this.setVariable();
        this.acceleration = new Vector(0, 0);
        this.aVelocity = window.innerWidth <= 768 ? 0.015 : 0.025; //角速度
        this.g = new PIXI.Container();
        //this.g.alpha = this.randLimit(0.2, 1);

        this.init();
    }

    init() {
        this.factory();
    }

    setVariable() {
        let limit = 0;

        if (this.type === 0) {
            limit = 90;
        } else if (this.type == 1 || this.type == 3) {
            limit = 45;
        } else {
            limit = 30;
        }

        return limit;
    }

    setVelocity(type) {
        //スマホ用にも速度を調整
        const settings = {
            wide: { type1: new Vector(0, 2), type2: new Vector(0, 1) },
            nrw: { type1: new Vector(0, 0.8), type2: new Vector(0, 0.4) },
        };

        if (window.innerWidth <= 768) {
            return type === 0 ? settings.nrw.type1 : settings.nrw.type2;
        } else {
            return type === 0 ? settings.wide.type1 : settings.wide.type2;
        }
    }

    randLimit(min, max) {
        return Math.random() * (max - min) + min;
    }

    applyForce(force) {
        const f = force.divide(this.mass);
        this.acceleration = this.acceleration.add(f);
    }

    checkEdges() {
        if (this.position.y >= window.innerHeight + this.g.height) {
            this.position.y = -this.g.height;
            this.position.x = window.innerWidth * Math.random();
            this.acceleration = new Vector(0, 0);
            this.velocity = this.setVelocity();
        }
    }

    update(t) {
        const gravity = new Vector(0, 0.000001);
        const amplitude = window.innerWidth <= 768 ? 0.5 : 1;

        //角速度を足して前後に振動させる
        this.rad = this.rad + this.aVelocity;
        this.applyForce(gravity);

        this.velocity = this.velocity.add(this.acceleration);
        this.position = this.position.add(this.velocity);

        //羽のゆらめき
        this.position = this.position.add(new Vector(amplitude * Math.sin(this.rad), 0));

        //角度を求める(0~180の間を反復)
        this.angle = this.angleVariable * Math.cos(this.rad) + this.angleVariable;

        this.checkEdges();
    }

    factory() {
        const sprite = this.getTypeSprite();
        this.setScale();
        this.g.alpha = this.randLimit(0.5, 1);
        this.g.addChild(sprite);
    }

    getTypeSprite() {
        //ロードしたアセットをSprite表示させる：手前にあるものほどぼかしたい
        let sprite;
        if (this.type == 0) {
            sprite = PIXI.Sprite.from(this.resource.f3.texture);
        } else if (this.type == 1 || this.type == 3) {
            sprite = PIXI.Sprite.from(this.resource.f1.texture);
        } else {
            sprite = PIXI.Sprite.from(this.resource.f2.texture);
        }

        return sprite;
    }

    setScale() {
        const width_scale = window.innerWidth / 1920;

        if (this.type == 0) {
            this.scale = window.innerWidth <= 768 ? this.randLimit(0.3, 0.45) : this.randLimit(0.6 * width_scale, 0.8 * width_scale);
        } else {
            this.scale = window.innerWidth <= 768 ? this.randLimit(0.15, 0.3) : this.randLimit(0.3 * width_scale, 0.5 * width_scale);
        }
    }

    getUnitV() {
        let distvector = this.distination.subtract(this.position).unit();
        return distvector;
    }

    display() {
        this.g.position.x = this.position.x;
        this.g.position.y = this.position.y;
        this.g.rotation = (this.angle * Math.PI) / 180;
        this.g.scale.x = this.g.scale.y = this.scale;

        if (this.type == 2 || this.type == 4) {
            this.g.pivot.x = this.g.width;
            this.g.pivot.y = 0;
        }
    }

    remap() {
        this.setScale(); //スケールの再セット
        this.position.x = window.innerWidth * Math.random(); //x位置のみ再セット
    }
}

import { gsap } from "gsap";
import Cookies from "js-cookie";

export default class Intro {
    constructor() {
        this.ua = window.navigator.userAgent.toLowerCase();
        this.init();
    }

    init() {
        const fv = Cookies.get("fv");
        const isFirefox = this.ua.indexOf("firefox") != -1;

        //扉のアニメーションはクッキーで1日1回に
        if (!location.search.match(/\?skip/) && !fv && !isFirefox) {
            this.intro();
        } else if (location.search.match(/\?skipall/)) {
            //ALL SKIP用
            document.body.classList.add("-skip");
            const loader = document.querySelector(".loader");
            loader.classList.add("-disable");
            loader.classList.add("-end");
        } else {
            document.body.classList.add("-skip");
            this.skippedAnimation();
        }
    }

    skippedAnimation() {
        const loader = document.querySelector(".loader");
        const tl = gsap.timeline({
            onComplete: () => {
                loader.classList.add("-end");
            },
        });
        loader.classList.add("-disable");

        const range = window.innerWidth < 768 ? 10 : 20;

        tl.fromTo(".mainvisual", 1.5, { opacity: 0, scale: 1.5 }, { opacity: 1, scale: 1, ease: "circ.inOut" }, 1);
        tl.fromTo(".mainvisual__ch", 1, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 2.5);

        tl.fromTo(".mainvisual__10thlogo", 1, { opacity: 0, y: range }, { opacity: 1, y: 0 }, 3);
        tl.fromTo(".mainvisual__credit,.mainvisual__bnr__obj", 1, { opacity: 0 }, { opacity: 1 }, 3);
        tl.fromTo(".mainvisual__catch__txt", 1, { opacity: 0, y: range }, { opacity: 1, y: 0 }, 3.2);
    }

    intro() {
        document.body.classList.add("-in_intro");

        const loader = document.querySelector(".loader");
        loader.classList.add("-disable");

        const intro_scroller = document.querySelector(".js-introscroller");
        const tl = gsap.timeline({
            onComplete: () => {
                document.body.classList.remove("-in_intro");
                loader.classList.add("-end");
            },
        });

        const range = window.innerWidth < 768 ? 10 : 20;

        tl.pause();
        tl.to(".intro__frame", 2, { scale: 1, ease: "circ.inOut" }, 0);
        tl.to(".intro", 1, { opacity: 0, display: "none" }, 1);
        tl.to(".intro__bg", 0.5, { opacity: 1 }, 0);
        tl.fromTo(".mainvisual", 1.5, { opacity: 0, scale: 1.5 }, { opacity: 1, scale: 1, ease: "circ.inOut" }, 1);
        tl.fromTo(".mainvisual__ch", 1, { opacity: 0, y: range }, { opacity: 1, y: 0 }, 2.5);

        tl.fromTo(".mainvisual__10thlogo", 1, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 3);
        tl.fromTo(".mainvisual__credit,.mainvisual__bnr__obj", 1, { opacity: 0 }, { opacity: 1 }, 3);
        tl.fromTo(".mainvisual__catch__txt", 1, { opacity: 0, y: range }, { opacity: 1, y: 0 }, 3.2);

        intro_scroller.addEventListener("scroll", (e) => {
            if (e.target.scrollTop > 100) {
                tl.play();
                Cookies.set("fv", 1, { expires: 1 });
            }
        });
    }
}

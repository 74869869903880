import Modal from "./modal";

export default class Main {
    constructor() {
        this.init();
    }

    init() {
        this.nav();
        this.modal = new Modal();
    }

    nav() {
        const $close = document.querySelectorAll(".js-menu__close");
        const $open = document.querySelector(".js-menu__open");
        const $menu = document.querySelector(".js-menu");
        const $ph = document.querySelector(".js-menu__ph");

        $close.forEach((el) => {
            el.addEventListener("click", () => {
                $menu.classList.remove("-active");
            });
        });

        $open.addEventListener("click", () => {
            //this.setFitScreen(); //chromeでバグるため必要…
            $menu.classList.add("-active");
            $ph.style.background = `url(${this.getRandomPh()}) no-repeat 0 0 /auto 100%`;
        });
    }

    setFitScreen() {
        const wh = window.innerHeight;
        document.documentElement.style.setProperty("--screenHeight", `${wh}px`);
    }

    getRandomPh() {
        const ep = Math.floor(Math.random() * 12) + 1;
        const number = Math.floor(Math.random() * 4) + 1;
        return `/assets/img/top/playback/ep${ep}/ph_scr_${number}.jpg`;
    }
}

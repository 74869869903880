import * as PIXI from "pixi.js";
import Feather from "./feather";

export default class Canvas {
    constructor() {
        this.app = null;
        this.feathers = [];
        this.resizeTimer = null;
        this.init();
    }

    init() {
        this.setupPixi();
        this.eventBind();
    }

    setupPixi() {
        this.app = new PIXI.Application({
            width: window.innerWidth,
            height: window.innerHeight,
            view: document.querySelector(".js-canvas"),
            transparent: true,
        });

        this.setLoader();
    }

    setLoader() {
        const loader = new PIXI.Loader();

        //ロード完了
        loader.onComplete.add(() => {
            console.log("Load:complete");
        });

        loader
            .add([
                { name: "f1", url: "./assets/img/top/mainvisual/feather/ph_f1.png" },
                { name: "f2", url: "./assets/img/top/mainvisual/feather/ph_f2.png" },
                { name: "f3", url: "./assets/img/top/mainvisual/feather/ph_f3.png" },
            ])
            .load((loader, resources) => {
                const fps = 60;
                let now = performance.now();
                let start = 0;

                const widthratio = window.innerWidth / 1920;
                const feathers = this.feathers;
                const amount = window.innerWidth <= 768 ? 14 : Math.floor(27 * widthratio);

                for (let i = 0; i < amount; i++) {
                    const feather = new Feather(Math.random() * window.innerWidth, Math.random() * window.innerHeight, i % 5, resources);
                    feathers.push(feather);
                    this.app.stage.addChild(feather.g);
                }

                let animate = (timestamp) => {
                    let delta = Math.floor(timestamp - now);

                    if (start === 0) {
                        start = timestamp;
                    }

                    let elapsed = (timestamp - start) / 1000;

                    //フレームスキップ実装はスマホではなしに（FPSがかなり出るため…）
                    if (window.innerWidth >= 768) {
                        //deltaが16.66ms(60FPS)以上あるときは、1フレームあたりの値がFPSが低くなっているので、
                        //本来の分だけupdateしてフレームスキップを実装する
                        while (delta >= 0) {
                            feathers.forEach((feather) => feather.update(elapsed));
                            delta -= 1000.0 / fps;
                        }
                    } else {
                        feathers.forEach((feather) => feather.update(elapsed));
                    }

                    //フレームスキップした結果を描画
                    feathers.forEach((feather) => feather.display());
                    now = timestamp;

                    requestAnimationFrame(animate);
                };

                animate(performance.now());
            });
    }

    eventBind() {
        window.addEventListener("resize", this.setFullscreenCanvas.bind(this));
    }

    setFullscreenCanvas() {
        //リサイズイベントがスクロールで連続で発生しないようにする
        clearTimeout(this.resizeTimer);
        const feathers = this.feathers;

        this.resizeTimer = setTimeout(() => {
            //ratio,canvasサイズ、描画領域を再計算(今回はretina対応はしない)
            this.app.renderer.resize(window.innerWidth, window.innerHeight);
            feathers.forEach((feather) => feather.remap());
        }, 100);
    }
}

import { PLAYER_MODULE_BRIGHTCOVE } from "js-player-module-brightcove";

export default class Modal {
    constructor() {
        this.YoutubeAPIReady = false;
        this.init();
    }

    init() {
        this.modal();
        this.movieModal();
        this.initYoutubeAPI();
        this.illustModal();
    }

    modal() {
        const $open_trigger = document.querySelectorAll(".js-modal__open");
        const $close_trigger = document.querySelectorAll(".js-modal__close");

        $close_trigger.forEach((el) => {
            const modals = document.querySelectorAll(".js-modal");

            el.addEventListener("click", () => {
                //自分自身の親のみ閉じる
                el.parentNode.classList.remove("-active");
            });
        });

        $open_trigger.forEach((el) => {
            const tgt = el.getAttribute("data-modal");
            const modal = document.querySelector(`.js-modal[data-modal="${tgt}"]`);

            el.addEventListener("click", () => {
                modal.classList.add("-active");
            });
        });
    }

    illustModal() {
        const $open_trigger = document.querySelectorAll(".js-illust__open");
        const $close_trigger = document.querySelectorAll(".js-illust__close");

        $open_trigger.forEach((el) => {
            const data = el.getAttribute("data-image");
            const width = el.getAttribute("data-width");
            const height = el.getAttribute("data-height");

            el.addEventListener("click", () => {
                const dom = document.querySelector(".js-illustdom");
                if (window.innerWidth <= 768) {
                    dom.style.height = `${(height / width) * 100}vw`;
                    dom.style.width = `100vw`;
                } else {
                    dom.style.height = `${height}px`;
                    dom.style.width = `${width}px`;
                }
                dom.style.background = `url(${data}) no-repeat 0 0 / 100% auto`;
            });
        });

        $close_trigger.forEach((el) => {
            const dom = document.querySelector(".js-illustdom");
            dom.style.height = `0px`;
            dom.style.width = `0px`;
            dom.style.background = `transparent`;
        });
    }

    movieModal() {
        const $open_trigger = document.querySelectorAll(".js-movie__open");
        const $close_trigger = document.querySelectorAll(".js-movie__close");

        $open_trigger.forEach((el) => {
            const data = el.getAttribute("data-movie").split(",");
            el.addEventListener("click", () => {
                //youtubeかbrightcoveかに応じて出し分け
                if (data[0] == "youtube") {
                    this.setYoutube(data[1]);
                    console.log("b");
                } else if (data[0] == "brightcove") {
                    this.setBrightcove(data[1]);
                    console.log("b");
                }
            });
        });

        $close_trigger.forEach((el) => {
            const $inner = document.getElementById("js-movieplayerdom");

            el.addEventListener("click", () => {
                $inner.innerHTML = "";
            });
        });
    }

    getVideoSize() {
        return {
            width: window.innerWidth <= 768 ? window.innerWidth + "px" : "800px",
            height: window.innerWidth <= 768 ? window.innerWidth * 0.5625 + "px" : "450px",
        };
    }

    setBrightcove(videoid) {
        const vsize = this.getVideoSize();

        const player = new PLAYER_MODULE_BRIGHTCOVE({
            id: "js-movieplayerdom",
            ui_controls: true,
            ui_default: false,
            videoid: videoid,
            account: "4929511769001",
            width: vsize.width,
            height: vsize.height,
        });

        return player;
    }

    initYoutubeAPI() {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => {
            this.YoutubeAPIReady = true;
        };
    }

    setYoutube(videoid) {
        if (!this.YoutubeAPIReady) {
            return;
        }

        const elm = document.createElement("div");
        elm.id = "youtubeIframe";
        document.getElementById("js-movieplayerdom").appendChild(elm);
        const vsize = this.getVideoSize();

        let player = new YT.Player("youtubeIframe", {
            width: vsize.width,
            height: vsize.height,
            videoId: videoid,
        });

        return player;
    }
}
